<template>
  <div align="center">
    <br>
    <div class="card col-md-8 rounded shadow-lg" align="left">
      <div class="card-body">
        <div class="card-title">
          <h1>Cambiar contraseña</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Usuario:</label>
                <p class="form-control col-md-6">{{ item.usu_cdgo }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Nombre:</label>
                <p class="form-control col-md-6">{{ item.usu_nmbre }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Apellido:</label>
                <p class="form-control col-md-6">{{ item.usu_aplldo }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Contraseña antigua (*):</label>
                <input type="password" class="form-control col-md-6" v-model="item.usu_pwd_ant" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Contraseña nueva (*):</label>
                <input type="password" class="form-control col-md-6" v-model="item.usu_pwd_new" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Confirmar (*):</label>
                <input type="password" class="form-control col-md-6" v-model="item.usu_pwd_conf" required>
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-6">
              <button type="submit" class="btn btn-success">Guardar</button>
            </div>
            <div class="col-6">
              <router-link :to="{ name: 'Home' }" class="btn btn-secondary">Cancelar</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  //feather.replace();

  export default {
    data(){
      return{
        item: {},
        message: ''
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.getItem(this.$store.state.user);
    },
    methods: {
      getItem(id){
        let uri = '/usuarios/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
        });
      },
      saveItem(){
        if (this.item.usu_pwd_new.length < 8){
          alert('Advertencia. La contraseña debe tener una longitud mínima de 8 caracteres.');
          return;
        }
        if (this.item.usu_pwd_new != this.item.usu_pwd_conf){
          alert('No se ha confirmado la nueva contraseña. Verifique los datos y vuelva a intentarlo.')
          this.item.usu_pwd_ant = '';
          this.item.usu_pwd_new = '';
          this.item.usu_pwd_conf = '';
          return;
        }
        let uri = '/usuarios/security';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, this.item).then((response) => {
          alert('La contraseña fue cambiada satisfactoriamente.');
          this.$router.replace({ name: 'Profile' });
        }).catch((err) => {
          alert('No se ha podido cambiar la contraseña. Verifique los datos y vuelva a intentarlo.');
          this.item.usu_pwd_ant = '';
          this.item.usu_pwd_new = '';
          this.item.usu_pwd_conf = '';
        });
      }

    } // END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
